import { useEffect, useState, useMemo } from "react";
import { db } from "../firebase/config";
import { collection, onSnapshot } from "firebase/firestore";
import { trace } from "firebase/performance";
import { getPerformance } from "firebase/performance";
import LZString from "lz-string";

const perf = getPerformance();
const CHUNK_SIZE = 5000;

// IndexedDB functions
const openIndexedDB = () => {
  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open("MyDatabase", 2);
    dbRequest.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("DataStore")) {
        db.createObjectStore("DataStore", { keyPath: "key" });
        console.log("Object store 'DataStore' created.");
      }
    };
    dbRequest.onsuccess = (event) => {
      resolve(event.target.result);
    };
    dbRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

const cacheInIndexedDB = async (key, data) => {
  try {
    const db = await openIndexedDB();
    const transaction = db.transaction(["DataStore"], "readwrite");
    const store = transaction.objectStore("DataStore");
    store.put({ key, data });
  } catch (error) {
    console.error("Error caching in IndexedDB:", error);
  }
};

const getFromIndexedDB = async (key) => {
  try {
    const db = await openIndexedDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["DataStore"], "readonly");
      const store = transaction.objectStore("DataStore");
      const getRequest = store.get(key);
      getRequest.onsuccess = () => resolve(getRequest.result?.data);
      getRequest.onerror = () => reject(null);
    });
  } catch (error) {
    console.error("Error getting from IndexedDB:", error);
    return null;
  }
};

// Cache data with chunking and compression
const cacheData = (key, data, ttl = 3600) => {
  try {
    const compressedData = LZString.compress(JSON.stringify(data));
    if (compressedData.length < CHUNK_SIZE) {
      const expirationTime = Date.now() + ttl * 1000;
      localStorage.setItem(
        key,
        JSON.stringify({ data: compressedData, expirationTime })
      );
    } else {
      cacheInIndexedDB(key, compressedData);
    }
  } catch (error) {
    console.error("Error caching data:", error);
  }
};

// Retrieve cached data with expiration logic
const getCachedData = async (key) => {
  try {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const { data, expirationTime } = JSON.parse(cachedData);
      if (Date.now() > expirationTime) {
        localStorage.removeItem(key);
        return null;
      }
      const decompressedData = LZString.decompress(data);
      return decompressedData ? JSON.parse(decompressedData) : null;
    }
    const indexedDBData = await getFromIndexedDB(key);
    if (indexedDBData) {
      const decompressedData = LZString.decompress(indexedDBData);
      return decompressedData ? JSON.parse(decompressedData) : null;
    }
    return null;
  } catch (error) {
    console.error("Error retrieving cached data:", error);
    // Optionally, remove corrupted cache
    localStorage.removeItem(key);
    await removeFromIndexedDB(key); // You'll need to implement this function
    return null;
  }
};

// Optional: Function to remove data from IndexedDB
const removeFromIndexedDB = async (key) => {
  try {
    const db = await openIndexedDB();
    const transaction = db.transaction(["DataStore"], "readwrite");
    const store = transaction.objectStore("DataStore");
    store.delete(key);
  } catch (error) {
    console.error("Error removing from IndexedDB:", error);
  }
};

const useGetData = (collectionName) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const collectionRef = useMemo(
    () => collection(db, collectionName),
    [collectionName]
  );

  useEffect(() => {
    let onSnapshotTrace;

    const cacheKey = `firestore-${collectionName}`;

    const fetchData = async () => {
      try {
        const cachedData = await getCachedData(cacheKey);
        if (cachedData) {
          setData(cachedData);
          setLoading(false);
        } else {
          onSnapshotTrace = trace(perf, "onSnapshotRead");
          onSnapshotTrace.start();

          const unsubscribe = onSnapshot(
            collectionRef,
            (snapshot) => {
              const newData = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));
              setData(newData);
              setLoading(false);
              cacheData(cacheKey, newData);

              if (onSnapshotTrace) {
                onSnapshotTrace.stop();
              }
            },
            (error) => {
              setError(error.message);
              setLoading(false);

              if (onSnapshotTrace) {
                onSnapshotTrace.stop();
              }
            }
          );

          // Cleanup subscription on component unmount
          return () => {
            unsubscribe();
            if (onSnapshotTrace && !onSnapshotTrace.isStopped) {
              onSnapshotTrace.stop();
            }
          };
        }
      } catch (err) {
        console.error("Error fetching or caching data:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [collectionRef, collectionName]);

  return { data, loading, error };
};

export default useGetData;
